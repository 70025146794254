/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    h3: "h3",
    p: "p",
    ul: "ul",
    li: "li",
    h4: "h4",
    code: "code",
    blockquote: "blockquote",
    pre: "pre"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Stack and Que"), "\n", React.createElement(_components.h3, null, "Definition of Stack"), "\n", React.createElement(_components.p, null, "A collection of data that operates under LIFO principles."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "LIFO: first in first out"), "\n"), "\n", React.createElement(_components.h4, null, "Real World Cases"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "managing function invocations"), "\n", React.createElement(_components.li, null, "undo/redo"), "\n", React.createElement(_components.li, null, "routing, history objects in browsers"), "\n"), "\n", React.createElement(_components.p, null, "A stack at it’s core is an abstract concept and there is some fluidity with the properties of the definition. Different languages have varying degrees of rigor and patterns `with how function is implemented."), "\n", React.createElement(_components.p, null, "JavaScript doesn't have a default stack data type but the array object using ", React.createElement(_components.code, null, ".push()"), " and ", React.createElement(_components.code, null, ".pop()"), " is functionality a stack."), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "💡 From a performance standpoint a JS array is not the most lean implementations of a stack as functionality associated with index's is not necessary within the rigorous definition of a stack."), "\n"), "\n", React.createElement(_components.h4, null, "Object Definition"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "class Node {\n  constructor(value) {\n    this.value = value\n    this.next = null\n  }\n}\n\nclass Stack {\n  constructor() {\n    this.first = null\n    this.last = null\n    this.size = 0\n  }\n  insert() {\n    let newNode = new Node(val)\n    if (!this.first) {\n      this.first = newNode\n      this.last = newNode\n    } else {\n      let tmp = this.first\n      this.first = newNode\n      this.first.next = tmp\n    }\n    return ++this.size\n  }\n  remove() {\n    if (!this.first) {\n      return null\n    }\n    let tmp = this.first\n    if ((this.first = this.last)) {\n      this.last = null\n    }\n    this.first = this.first.next\n    this.size--\n    return tmp.value\n  }\n}\n")), "\n", React.createElement(_components.h4, null, "Functional Definition"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "const node = (value) => {\n  return {\n    Node: value,\n    next: null,\n  }\n}\nconst stack = (size, insert, remove) => {}\n")), "\n", React.createElement(_components.h4, null, "Stack data structure built with linked lists"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  })), "\n", React.createElement(_components.h3, null, "Queu"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "FIFO"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
